const BurgerButton = (props: any) => {
  const burgerButtonIcon = () => {
    return (
      <svg version='1.1' xmlns='http://www.w3.org/2000/svg' x='0' y='0' viewBox='0 0 24 24'>
        <path fill='currentColor' id='burger-button-bottom' d='M1 18h16v2H1z'></path>
        <path fill='currentColor' id='burger-button-top' d='M1 4h22v2H1z'></path>
        <path fill='currentColor' id='burger-button-center' d='M1 11h22v2H1z'></path>
      </svg>
    )
  }

  return (
    <div className={`burger-button ${props.className ?? ''}`} onClick={props.onClick}>
      <button type='button' className='dfb-IconButton dfb-IconButton--big'>
        <span className='dfb-Icon dfb-IconButton-icon'>{burgerButtonIcon()}</span>
        <span className='dfb-IconButton-label'>open Navigation</span>
      </button>
    </div>
  )
}

export default BurgerButton
