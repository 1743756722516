import { useRef, useContext, useEffect } from 'react'
import { NavigationContext, NavigationContextType } from '../context/NavigationContext'

export const useNav = (sectionId: string) => {
  const { addSection } = useContext(NavigationContext) as NavigationContextType
  const ref = useRef(null)

  useEffect(() => {
    addSection(sectionId, ref)
  }, [addSection, sectionId])

  return ref
}
