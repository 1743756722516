import React from 'react'

type DataLogoCard = {
  logo: string
  link: string
  className?: string
}

const LogoCard = (props: DataLogoCard) => {
  return (
    <a href={props.link} target='_blank' rel='noreferrer'>
      <div className='card-logo'>
        <img className={props.className} src={props.logo} alt='' />
      </div>
    </a>
  )
}

export default LogoCard
