import React from 'react';

export interface StepsProps {
  statusHeadline?: string,
  delimiter?: string,
  current: number,
  maximum: number,
}

function MassnahmenLoader(props: StepsProps) {

  const progress = (100 / props.maximum) * props.current;

  return (
    // <div className="dfb-Steps">
    <div className="dfb-Progress massnahmen__progress">
      <div className="dfb-Progress-progress">
        <span style={{ width: `${progress}%` }} className="dfb-Progress-bar"></span>
      </div>
    </div>
    // </div>
  );
}

export default MassnahmenLoader;
