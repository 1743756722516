import React from 'react'
import { Anchor, Card, CardContent } from '@dfb/dfb-ui-react/brands/dachmarke'

type DataSectionCard = {
  teaser?: string
  titel?: string
  subtitel?: string
  description?: string
  modal?: any
  link?: string
  linkLabel?: string
  onClick?: () => void
}

const SectionCard = (props: DataSectionCard) => {
  return (
    <div className='section-card' onClick={props.onClick}>
      <Card className={`${!props.link && !props.onClick ? 'dfb-Card--no-link' : ''}`}>
        {props.link && (
          <a
            href={props.link}
            className='section-card__link'
            aria-hidden='true'
            tabIndex={-1}
            target='_blank'
            rel='noreferrer'>
          </a>
        )}
        <CardContent>
          {props.teaser && <p className='section-card__teaser'>{props.teaser}</p>}
          {props.titel && <h5 className='section-card__titel'>{props.titel}</h5>}
          {props.subtitel && <h5 className='section-card__subtitel'>{props.subtitel}</h5>}
          {props.description && <p className='section-card__description'>{props.description}</p>}
          {props.link && (
            <a className={`dfb-Anchor dfb-Anchor--secondary`} href={props.link} target='_blank' rel='noreferrer'>
              <span className='dfb-Anchor-label'>{props.linkLabel}</span>
            </a>
          )}
          {props.onClick && <Anchor type={'secondary'} label={props.linkLabel} onClick={props.onClick} />}
        </CardContent>
      </Card>
    </div>
  )
}

export default SectionCard
