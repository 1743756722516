import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import * as DOMPurify from 'dompurify'
import { NavigationContext, NavigationContextType } from '../../context/NavigationContext'

import Navigation from '../../data/navigation'

const StickySection = () => {
  const { t } = useTranslation()
  const { activeSection } = useContext(NavigationContext) as NavigationContextType

  const navigate = (id: string) => {
    document.getElementById(id)?.scrollIntoView({ behavior: 'smooth' })
  }

  return (
    <div className='sticky-item'>
      {Navigation.map(({ label, imghref }, index) => (
        <div
          className={`sticky-item__content ${activeSection === label ? 'sticky-item__content--active' : ''} `}
          key={index}
          onClick={e => navigate(label)}>
          <img src={imghref} alt={`${t(`nav.${label}.menu`)}`} className='sticky-item__logo' />
          <div
            className='sticky-item__title'
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(t([`nav.${label}.sticky`, `nav.${label}.menu`]) || '', {
                USE_PROFILES: { html: true }
              })
            }}></div>
        </div>
      ))}
    </div>
  )
}

export default StickySection
